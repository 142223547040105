import React from "react"
import { Link } from "gatsby"

import "../styles/footer.scss"

import instagram from "../images/instagram.svg"
import twitter from "../images/twitter.svg"
import youtube from "../images/youtube.svg"
import agbo from "../images/agbo.png"

const Footer = () => {
  return (
    <div id="footer" className="relative footer">
      <div className="footerWrapper">
        <div className="pb-8 socialLinks">
          <a
            target="_blank"
            href="https://www.instagram.com/agboleague/"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="mx-4"
            href="https://twitter.com/agboleague/"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/playlist?list=PLtwujFMxTRWiB_Y0Dm4eZk-70tlihvpc9"
          >
            <img src={youtube} alt="" />
          </a>
        </div>
        <div className="pb-16 socialLinks">
          <a target="_blank" href="http://agbo.com/" rel="noreferrer">
            <img src={agbo} alt="AGBO" className="logo" />
          </a>
        </div>
      </div>
      <div className="my-8 text">
        <p className="text-sm italic font-russo">
          COPYRIGHT &copy; {new Date().getFullYear()}{" "}
          <a
            target="_blank"
            href="http://agbo.com/"
            rel="noreferrer"
            className="font-extrabold tracking-wide tangent"
          >
            AGBO
          </a>
        </p>
        <p className="text-sm italic font-russo">
          SITE DESIGN BY{" "}
          <a
            target="_blank"
            href="https://www.thetangentagency.com/"
            className="font-extrabold tracking-wide tangent"
            rel="noreferrer"
          >
            THE TANGENT AGENCY
          </a>
        </p>
        {false && (
          <Link
            className="block mt-12 text-sm text-center md:text-lg"
            to="/league-rules-settings-schedule"
          >
            Rules & Settings
          </Link>
        )}
      </div>
    </div>
  )
}

export default Footer
