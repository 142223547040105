import React from "react"
import { Media } from "../Media"
import Navigation from "./navigation"
import scrollTo from "gatsby-plugin-smoothscroll"
import "../styles/header.scss"
// Mobile assets import
import logoMobile from "../images/mobile/header/logo.png"
import backgroundMobile from "../images/mobile/header/background.png"

// Full assets import
import logoFull from "../images/full/header/logo.png"
import backgroundFull from "../images/full/header/background.png"
import logoVideo from "../images/tan_asfl_logo-loop_v02_small.mp4"
import logoVideoPoster from "../images/TAN_ASFL_Logo-Loop_v02.png"

const Header = () => {
  return (
    <>
      <Navigation />
      <div
        id="header"
        className="pointer-events-none relative overflow-hidden min-h-screen w-full "
      >
        <Media greaterThanOrEqual="md">
          <div className="h-screen flex justify-center items-center">
            <video
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              poster={logoVideoPoster}
            >
              <source src={logoVideo} type="video/mp4" />
            </video>
          </div>
        </Media>

        <Media at="sm" className="mobileHeader">
          <img className="background" src={backgroundMobile} alt="bg" />
          <img
            className="logo w-11/12 mx-auto"
            src={logoMobile}
            alt="AGBO Superhero Fantasy Football"
          />
        </Media>
        {false && (
          <Media greaterThanOrEqual="md" className="fullHeader">
            <img className="background" src={backgroundFull} alt="bg" />
            <img
              className="logo w-2/3 xl:w-1/2 mx-auto"
              src={logoFull}
              alt="AGBO Superhero Fantasy Football"
            />
          </Media>
        )}
        <Media at="sm" className="cursor-pointer">
          <div className="chevron">
            <button
              onClick={() => scrollTo(`#overview`)}
              className="w-full cursor-pointer"
            >
              <div className="shadow"></div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#fff"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="arcs"
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </button>
          </div>
        </Media>
      </div>
    </>
  )
}

export default Header
