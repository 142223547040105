import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Media } from "../Media"

import "../styles/navigation.scss"

export default function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false)

  const links = [
    { anchor: "header", text: "Home", enabled: true },
    { anchor: "players", text: "Players", enabled: true },
    { anchor: "standings", text: "Standings", enabled: true },
    { anchor: "schedule", text: "Schedule", enabled: true },
    { anchor: "scores", text: "Scores", enabled: false },
    { anchor: "social", text: "Social", enabled: true },
    { anchor: "press", text: "Press", enabled: true },
    { anchor: "charities", text: "Charities", enabled: true },
    { anchor: "newsletter", text: "Newsletter", enabled: true },
  ]

  const goAndClose = id => {
    scrollTo(id)
    setMenuOpen(false)
  }

  return (
    <div className="fixed left-0 right-0 z-50 flex items-center justify-center navigation">
      <Media greaterThanOrEqual="md" className="fullHeader">
        <nav className="flex items-center justify-center">
          {links
            .filter(el => el.enabled)
            .map(singleLink => (
              <button
                onClick={() => goAndClose(`#${singleLink.anchor}`)}
                key={singleLink.text}
                className="px-1 text-sm uppercase cursor-pointer md:pt-8 lg:px-2 xl:px-3 md:pb-16 md:text-lg lg:text-xl xxl:text-2xl hover:underline"
              >
                {singleLink.text}
              </button>
            ))}
        </nav>
      </Media>
      <Media at="sm" className="mobileHeader">
        <div className="flex items-center justify-center w-screen">
          <button
            type="button"
            className="block pt-6 pb-16 text-white focus:outline-none"
            onClick={() => setMenuOpen(el => !el)}
          >
            <svg className="w-16 h-16 fill-current" viewBox="0 0 24 24">
              {menuOpen && (
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              )}
              {!menuOpen && (
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>

        {menuOpen && (
          <div className="flex flex-col w-full h-screen p-4">
            {links
              .filter(el => el.enabled)
              .map(singleLink => (
                <button
                  onClick={() => goAndClose(`#${singleLink.anchor}`)}
                  key={`mobile-${singleLink.text}`}
                  className="px-3 py-2 text-xl text-center uppercase cursor-pointer"
                >
                  {singleLink.text}
                </button>
              ))}
          </div>
        )}
      </Media>
    </div>
  )
}
